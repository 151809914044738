import { ParsedUrlQuery } from 'querystring';

import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { FilterCollectionQueries } from 'enums/Filter';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import '@splidejs/react-splide/css/core';
import useMediaQuery from 'utils/useMediaQuery';
import { Icon } from 'components/tokens/Icon';
import { touch } from 'icons/default';
import { Colors } from 'enums/Colors';

import styles from './Collections.module.scss';

interface IFilterItem {
  text: string;
  queryKey: string;
  isActive: boolean;
}

type TFilterItems = Array<IFilterItem>;

const FILTER_ITEMS = [
  {
    text: 'Laddbara bilar',
    queryKey: FilterCollectionQueries.Rechargeable,
    isActive: false,
  },
  {
    text: 'Populäraste bilarna',
    queryKey: FilterCollectionQueries.PopularCars,
    isActive: false,
  },
  {
    text: 'Stort bagageutrymme',
    queryKey: FilterCollectionQueries.BigLuggage,
    isActive: false,
  },
  {
    text: 'Flest antal sittplatser',
    queryKey: FilterCollectionQueries.NumberOfSeats,
    isActive: false,
  },
  {
    text: 'Elbilar med lång räckvidd',
    queryKey: FilterCollectionQueries.LongRange,
    isActive: false,
  },
  {
    text: 'Låg månadskostnad',
    queryKey: FilterCollectionQueries.Price,
    isActive: false,
  },
  {
    text: 'Små citybilar',
    queryKey: FilterCollectionQueries.SmallCityCars,
    isActive: false,
  },
  {
    text: 'Bilar yngre än 3 år',
    queryKey: FilterCollectionQueries.NewCars,
    isActive: false,
  },
  {
    text: 'Bilar som kan företagsleasas',
    queryKey: FilterCollectionQueries.CompanyLeasableVehicles,
    isActive: false,
  },
];

const getActiveFilteredItems = (query: ParsedUrlQuery) => {
  const activeFilterQueries = Object.keys(query);

  return FILTER_ITEMS.reduce<TFilterItems>((acc, curr) => {
    if (activeFilterQueries.includes(curr.queryKey)) {
      return [...acc, { ...curr, isActive: !curr.isActive }];
    }

    return [...acc, curr];
  }, []);
};

interface IFilter {}

export const Collections: React.VFC<IFilter> = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const router = useRouter();
  const activeFilteredItems = useMemo(() => getActiveFilteredItems(router.query), [router.query]);

  const handleButtonOnClick = useCallback(
    (queryKey) => {
      const activeQuery = activeFilteredItems.find(
        (item) => item.queryKey === queryKey && !item.isActive
      );

      router.push(
        {
          pathname: '/fordon',
          query: activeQuery ? { [activeQuery.queryKey]: '' } : {},
        },
        undefined,
        { shallow: true }
      );
    },
    [activeFilteredItems, router]
  );

  return (
    <div>
      {isMobile ? (
        <>
          <div className={styles['slider-wrapper']}>
            <Splide
              options={{
                arrows: false,
                autoWidth: true,
                pagination: false,
                gap: '0.5rem',
                padding: { right: '2rem', left: '0' },
              }}
              aria-label="Filtrerings kategorier"
            >
              {activeFilteredItems.map(({ isActive, queryKey, text }) => (
                <SplideSlide className={styles['image-wrapper']} key={queryKey}>
                  <Button
                    onClick={() => handleButtonOnClick(queryKey)}
                    type={ButtonType.Button}
                    variant={
                      isActive ? ButtonVariant.GhostInverted : ButtonVariant.PrimaryYellowish
                    }
                    size={ButtonSize.Small}
                  >
                    {text}
                  </Button>
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <div className={styles['icon-touch-wrapper']}>
            <Icon icon={touch} color={Colors.White} size={40} />
          </div>
        </>
      ) : (
        <ul className={styles.list}>
          {activeFilteredItems.map(({ isActive, queryKey, text }) => (
            <li key={queryKey}>
              <Button
                onClick={() => handleButtonOnClick(queryKey)}
                type={ButtonType.Button}
                variant={isActive ? ButtonVariant.GhostInverted : ButtonVariant.PrimaryYellowish}
                size={ButtonSize.Small}
              >
                {text}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
