import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import { InputText } from 'components/InputText';
import { Button, ButtonSize, ButtonType } from 'components/Button';
import { ClientApiService } from 'services/ClientApiService';
import useDebounce from 'utils/useDebounce';
import { getMarketplaceAdsParams } from 'utils/marketplaceAdsFilter';
import { FilterQueries } from 'enums/Filter';

import LocationDropdown from '../LocationDropdown/LocationDropdown';

import styles from './Search.module.scss';

const Search = () => {
  const methods = useForm();
  const [carNames, setCarNames] = useState<string[]>([]);
  const { query, push, isReady: routerIsReady } = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const searchTerm = methods.watch('searchTerm');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const isWaitingForFirstSearch = useRef(true);

  const onSubmit = (data: any) => {
    const newQuery: any = { search: data.searchTerm };

    if (query[FilterQueries.Location]) {
      newQuery[FilterQueries.Location] = query[FilterQueries.Location];
    }

    push(
      {
        pathname: '/fordon',
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  };

  const onClickDropdown = (result: string) => {
    methods.setValue('searchTerm', result);
    methods.handleSubmit(onSubmit)();
    setIsDropdownOpen(false);
  };

  const onSearch = async (searchValue: string) => {
    const params = getMarketplaceAdsParams({
      filters: { tag: '' },
      search: searchValue,
      plats: query.plats as string,
    });

    const response = await ClientApiService.proxyRequest({
      method: 'getMarketplaceAds',
      payload: { params },
    });
    const data = await response.json();
    const names = data.body?.payload.items.map((ad: any) => ad.vehicle.car_name);
    setCarNames(names.slice(0, 5));
    if (!isWaitingForFirstSearch.current || !query.plats) {
      // to prevent dropdown from opening on first search when there is a set search term in the url
      setIsDropdownOpen(true);
    }
    isWaitingForFirstSearch.current = false;
  };

  const onClickInput = () => {
    if (carNames.length > 0) {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm?.length > 2) {
      onSearch(debouncedSearchTerm);
    } else {
      setCarNames([]);
      setIsDropdownOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!query.search) {
      methods.setValue('searchTerm', query.search);
    }
  }, [query, methods]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement) {
        if (
          !event.target.closest(`.${styles.searchDropdown}`) &&
          !event.target.closest(`.${styles.input}`)
        ) {
          setIsDropdownOpen(false);
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (routerIsReady) {
      methods.setValue('searchTerm', query.search);
      methods.setValue(FilterQueries.Location, query.plats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerIsReady]);

  return (
    <div className={styles.wrapper}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.inputWrapper}>
            {/* eslint-disable-next-line  */}
            <div className={styles.input} onClick={onClickInput}>
              <InputText label="Sök märke eller modell" name="searchTerm" type="text" />
            </div>
            <div className={styles.buttonWrapper}>
              <Button size={ButtonSize.Large} type={ButtonType.Submit}>
                Sök
              </Button>
            </div>
          </div>
          <LocationDropdown />
        </form>
      </FormProvider>
      {isDropdownOpen && (
        <div className={styles.searchDropdown}>
          {carNames.map((result) => (
            <button
              key={result}
              type="button"
              className={styles.searchRow}
              onClick={() => onClickDropdown(result)}
            >
              {result}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Search;
