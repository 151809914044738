import { ParsedUrlQuery } from 'querystring';

import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import { useVehiclesFilterData } from 'lib/api/swr/useVehiclesFilterData';
import { getMarketplaceAdsParams } from 'utils/marketplaceAdsFilter';
import { Filters } from 'types/MarketPlaceAdsFilterResponse';
import { FilterQueries } from 'enums/Filter';
import { getQueryParams } from 'utils/FilterQueries';

export interface IFilterContext {
  filterData?: Filters;
  filterIsLoading: boolean;
  filterError?: any;
  filterSeriesData?: Filters['series'];
  filterSeriesIsLoading: boolean;
  filterSeriesError?: any;
}

export const FilterContext = React.createContext<IFilterContext>({
  filterData: undefined,
  filterIsLoading: true,
  filterError: undefined,
  filterSeriesData: undefined,
  filterSeriesIsLoading: true,
  filterSeriesError: undefined,
});

const getQueryWithOnlyBrand = (query: ParsedUrlQuery) => {
  if (query[FilterQueries.Brand]) {
    return { [FilterQueries.Brand]: query[FilterQueries.Brand] };
  }
  return {};
};

export const FilterProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { query } = useRouter();
  const queryWithOnlyBrand = getQueryWithOnlyBrand(query);
  const paramsWithOnlyBrand = getMarketplaceAdsParams(getQueryParams(queryWithOnlyBrand));
  const {
    data: filterData,
    isLoading: filterIsLoading,
    error: filterError,
  } = useVehiclesFilterData(getMarketplaceAdsParams({}));
  const {
    data: filterSeriesData,
    isLoading: filterSeriesIsLoading,
    error: filterSeriesError,
  } = useVehiclesFilterData(paramsWithOnlyBrand);

  const value = useMemo(
    () => ({
      filterData,
      filterIsLoading,
      filterError,
      filterSeriesData: filterSeriesData?.series,
      filterSeriesIsLoading,
      filterSeriesError,
    }),

    [
      filterData,
      filterError,
      filterIsLoading,
      filterSeriesData,
      filterSeriesError,
      filterSeriesIsLoading,
    ]
  );

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};
