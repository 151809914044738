import React from 'react';
import Image from 'next/image';
import { useForm, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from 'next/router';

import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import { arrowRight } from 'icons/default';

import { inputBorderStyling } from '../sections/ControlHero/inputBorderStyling';

import styles from './ControlCard.module.scss';

interface Props {
  variant?: string;
}

const validationSchema = yup
  .object({
    licensePlate: yup
      .string()
      .matches(/^[A-Za-z]{3}\d{2}[A-Za-z\d]$/, 'Vänligen fyll i ett korrekt registreringsnummer')
      .label('Registreringsnummer'),
  })
  .required();

interface FormData {
  licensePlate: string;
}

enum Text {
  Header = 'Har du redan hittat en bil?',
  Body = 'Du vet väl att du kan köpa nästan vilken begagnad bil du vill med Blipp? Oavsett om det är en bil du hittat på Blocket, av en kompis eller grannen.',
}

export const ControlCard: React.FC<Props> = ({ variant }) => {
  const router = useRouter();
  const methods = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const value = methods.watch('licensePlate');

  const onSubmit = (data: FormData) => {
    router.push(
      {
        pathname: '/kopa-begagnad-bil/[licensePlate]',
        query: { licensePlate: data.licensePlate },
      },
      '/kopa-begagnad-bil/[licensePlate]'
    );
  };

  return (
    <div className={variant && styles.full}>
      <FormProvider {...methods}>
        <form className={styles.wrapper} onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={styles.content}>
            <h3>{Text.Header}</h3>
            <p>{Text.Body}</p>
            <div className={styles.inner}>
              <div className={styles['number-plate']}>
                <Image
                  src="/assets/images/number-plate.svg"
                  alt="Number plate"
                  width={30}
                  height={60}
                />
                <label htmlFor="licensePlate" className="u-a11y-hide">
                  Registreringsnummer:
                </label>
                <input
                  {...methods.register('licensePlate')}
                  id="licensePlate"
                  type="text"
                  style={inputBorderStyling[value?.length ?? 0]}
                  maxLength={6}
                  autoComplete="off"
                />
              </div>
              <ErrorMessage
                name="licensePlate"
                errors={methods.formState.errors}
                render={({ message }) => <span className={styles['error-message']}>{message}</span>}
              />
              <Button
                type={ButtonType.Submit}
                trailingIcon={arrowRight}
                size={ButtonSize.Large}
                variant={ButtonVariant.Primary}
              >
                Kontrollera
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
