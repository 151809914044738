export const getParsedUrlQueryAsString = (queryValue: string | string[] | undefined): string => {
  if (!queryValue) {
    return '';
  }

  if (Array.isArray(queryValue)) {
    return queryValue[0];
  }

  return queryValue;
};

export const getParsedUrlQueryAsStringArray = (
  queryValue: string | string[] | undefined
): string[] => {
  if (!queryValue) {
    return [];
  }

  if (Array.isArray(queryValue)) {
    return queryValue;
  }

  return [queryValue];
};

export const getParsedUrlQueryAsNumber = (
  queryValue: string | string[] | undefined
): number | undefined => {
  if (Array.isArray(queryValue)) {
    return parseInt(queryValue[0], 10);
  }

  if (queryValue) {
    return parseInt(queryValue, 10);
  }

  return undefined;
};
