export enum FilterCollectionQueries {
  BigLuggage = 'stort-bagageutrymme',
  NumberOfSeats = 'flest-antal-sittplatser',
  LongRange = 'lang-rackvidd',
  Price = 'lag-manadskostnad',
  PopularCars = 'popularaste-bilarna',
  SmallCityCars = 'sma-citybilar',
  NewCars = 'bilar-yngre-an-3-ar',
  CompanyLeasableVehicles = 'kan-foretagleasas',
  Rechargeable = 'laddbara-bilar',
}

export enum FilterQueries {
  Location = 'plats',
  VehicleType = 'fordonstyp',
  Brand = 'marke',
  Series = 'modell',
  PriceMin = 'pris-min',
  PriceMax = 'pris-max',
  YearModelMin = 'arsmodell-min',
  YearModelMax = 'arsmodell-max',
  HorsepowerMin = 'hastkraft-min',
  HorsepowerMax = 'hastkraft-max',
  MileageMin = 'miltal-min',
  MileageMax = 'miltal-max',
  RangeMin = 'rackvidd-min',
  RangeMax = 'rackvidd-max',
  GearBox = 'vaxellada',
  Colors = 'farger',
  Fuel = 'drivmedel',
}

export enum GearBox {
  Automatic = 'automat',
  Manuel = 'manuell',
  Both = '',
}

export enum FuelHardCoded {
  Electric = 'Electric',
  Environment = 'Environment',
  Gasoline = 'Gasoline',
  Diesel = 'Diesel',
}

export enum LocationHardCoded {
  Blekinge = 'blekinge',
  Dalarna = 'dalarna',
  Gotland = 'gotland',
  Gavleborg = 'gavleborg',
  Halland = 'halland',
  Jamtland = 'jamtland',
  Jonkoping = 'jonkoping',
  Kalmar = 'kalmar',
  Kronoberg = 'kronoberg',
  Norrbotten = 'norrbotten',
  Skane = 'skane',
  Stockholm = 'stockholm',
  Sodermanland = 'sodermanland',
  Uppsala = 'uppsala',
  Varmland = 'varmland',
  Vasterbotten = 'vasterbotten',
  Vasternorrland = 'vasternorrland',
  Vastmanland = 'vastmanland',
  VastraGötaland = 'vastra gotaland',
  Orebro = 'orebro',
  Ostergotland = 'ostergotland',
}
