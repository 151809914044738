import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouter } from 'next/router';

import { Dropdown } from 'components/Dropdown';
import { DropdownSize } from 'components/Dropdown/Dropdown';
import { FilterQueries, LocationHardCoded } from 'enums/Filter';
import { Icon } from 'components/tokens/Icon';
import { Colors } from 'enums/Colors';
import { pin } from 'icons/default';

import { getSelectOptions } from '../Filter/utils';

import styles from './LocationDropdown.module.scss';

const municipalityCountys = getSelectOptions(Object.values(LocationHardCoded), 'Hela Sverige');

const LocationDropdown = () => {
  const { register, setValue } = useFormContext();
  const { query, push } = useRouter();

  const handleLocationChange = (event: any) => {
    const value = event?.currentTarget?.value;
    setValue(FilterQueries.Location, value);
    const newQuery = { ...query };
    if (value) {
      newQuery[FilterQueries.Location] = value;
    } else {
      delete newQuery[FilterQueries.Location];
    }

    push(
      {
        pathname: '/fordon',
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <div className={styles.locationDropdown}>
      <div className={styles.iconWrapper}>
        <Icon icon={pin} color={Colors.DarkBlue300} size={20} />
      </div>
      <Dropdown
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...register(FilterQueries.Location, { onChange: handleLocationChange })}
        options={municipalityCountys}
        size={DropdownSize.Medium}
      />
    </div>
  );
};

export default LocationDropdown;
