export enum Translations {
  Car = 'Bil',
  Mc = 'Motorcykel',
  Moped = 'Moped',
  MopedCar = 'Mopedbil',
  Snowmobile = 'Snöskoter',
  Trailer = 'Släpvagn',
  Caravan = 'Husvagn',
  Truck = 'Transportbil',
  Motorhome = 'Husbil',
  QuadBike = 'Fyrhjuling',
  Tractor = 'Traktor',
  Other = 'Other',
  Black = 'Svart',
  Blue = 'Blå',
  Brown = 'Brun',
  Green = 'Grön',
  Grey = 'Grå',
  Orange = 'Orange',
  Purple = 'Lila',
  Red = 'Röd',
  Silver = 'Silver',
  White = 'Vit',
  Yellow = 'gul',
  Electric = 'El',
  Environment = 'Miljöbränsle/Hybrid',
  Gasoline = 'Bensin',
  Diesel = 'Diesel',
  Vehicle = 'Fordon',
  Blekinge = 'Blekinge',
  Dalarna = 'Dalarna',
  Gotland = 'Gotland',
  Gavleborg = 'Gävleborg',
  Halland = 'Halland',
  Jamtland = 'Jämtland',
  Jonkoping = 'Jönköping',
  Kalmar = 'Kalmar',
  Kronoberg = 'Kronoberg',
  Norrbotten = 'Norrbotten',
  Skane = 'Skåne',
  Stockholm = 'Stockholm',
  Sodermanland = 'Södermanland',
  Uppsala = 'Uppsala',
  Varmland = 'Värmland',
  Vasterbotten = 'Västerbotten',
  Vasternorrland = 'Västernorrland',
  Vastmanland = 'Västmanland',
  VastraGotaland = 'Västra Götaland',
  Orebro = 'Örebro',
  Ostergotland = 'Östergötland',
}
