import React, { useMemo } from 'react';
import Link from 'next/link';

import { Icon } from 'components/tokens/Icon';
import { CarActionsSearch } from 'icons/symbol';
import { Colors } from 'enums/Colors';
import useMediaQuery from 'utils/useMediaQuery';
import { battery } from 'icons/default';

import styles from './StatusWithIcon.module.scss';

export enum StatusWithIconVariant {
  NoResult = 'noResult',
  Error = 'error',
}

export enum StatusWithIconIconSize {
  Small = 'small',
  Medium = 'medium',
}

interface ILink {
  href: string;
  text: string;
}

interface IStatusWithIcon {
  variant: StatusWithIconVariant;
  text: string;
  title: string;
  link: ILink;
  sizeIcon?: StatusWithIconIconSize;
}

export const StatusWithIcon: React.VFC<IStatusWithIcon> = ({
  title,
  text,
  link,
  variant,
  sizeIcon = StatusWithIconIconSize.Medium,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const iconSizeMobile = sizeIcon === StatusWithIconIconSize.Medium ? 150 : 40;
  const iconSizeDesktop = sizeIcon === StatusWithIconIconSize.Medium ? 252 : 80;

  const icon = useMemo(() => {
    switch (variant) {
      case StatusWithIconVariant.Error:
        return battery;
      case StatusWithIconVariant.NoResult:
        return CarActionsSearch;
      default:
        return CarActionsSearch;
    }
  }, [variant]);

  return (
    <div
      className={`${styles['no-results']} ${
        styles[`${sizeIcon === StatusWithIconIconSize.Small ? 'small' : 'medium'}`]
      }`}
    >
      <div
        className={`${styles['icon-wrapper']} ${
          variant === StatusWithIconVariant.Error ? styles['icon-wrapper--error'] : ''
        }`}
      >
        <Icon color={Colors.White} icon={icon} size={isMobile ? iconSizeMobile : iconSizeDesktop} />
      </div>
      <h2 className={styles.title}>{title}</h2>
      {text ? (
        <div className={styles.text}>
          {text}{' '}
          {link ? (
            <Link href={link.href} passHref>
              <a className={styles.link}>{link.text}</a>
            </Link>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
