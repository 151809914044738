import React, { useCallback, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Icon } from 'components/tokens/Icon';
import { chevronLeft, chevronRight } from 'icons/default';
import { Colors } from 'enums/Colors';

import styles from './Paginate.module.scss';

const SHOW_TRUNCATE_THRESHOLD = 5;

interface IPaginate {
  color?: Colors;
  currentPage?: number;
  itemsTotal: number;
  itemsPerPage: number;
  pathname: string;
  queryNameForPage?: string;
}

const getMinNumber = (currentPage: number, numberOfPaginationPages: number) => {
  switch (true) {
    case numberOfPaginationPages <= SHOW_TRUNCATE_THRESHOLD:
    case currentPage === 1:
    case currentPage === 3:
      return 1;
    case currentPage === numberOfPaginationPages - 1:
      return currentPage - 2;
    case currentPage === numberOfPaginationPages:
      return currentPage - 3;
    default:
      return currentPage - 1;
  }
};

const getMaxNumber = (currentPage: number, numberOfPaginationPages: number) => {
  switch (true) {
    case numberOfPaginationPages <= SHOW_TRUNCATE_THRESHOLD:
    case currentPage === numberOfPaginationPages:
      return numberOfPaginationPages;
    case currentPage === 1:
    case currentPage === 2:
      return 4;
    default:
      return currentPage + 1;
  }
};

export const Paginate: React.VFC<IPaginate> = ({
  color = Colors.White,
  itemsTotal,
  currentPage = 1,
  itemsPerPage,
  pathname,
  queryNameForPage = 'page',
}) => {
  const { query } = useRouter();
  const numberOfPaginationPages = Math.ceil(itemsTotal / itemsPerPage);

  const hrefUrl = useCallback(
    (pageNumber) => ({
      pathname,
      query: { ...query, [queryNameForPage]: pageNumber },
    }),
    [pathname, query, queryNameForPage]
  );

  const ListItem = useCallback(
    ({ isActive = false, content }) => (
      <li>
        <Link href={hrefUrl(content)} passHref>
          <a className={`${styles['btn-number']} ${isActive ? styles['btn-number--active'] : ''}`}>
            {content}
          </a>
        </Link>
      </li>
    ),
    [hrefUrl]
  );

  const numbers = useMemo(() => {
    const numbersArray: JSX.Element[] = [];
    const shouldShowTruncate = numberOfPaginationPages > SHOW_TRUNCATE_THRESHOLD;
    const minNumber = getMinNumber(currentPage, numberOfPaginationPages);
    const maxNumber = getMaxNumber(currentPage, numberOfPaginationPages);

    if (shouldShowTruncate && currentPage >= 4) {
      numbersArray.push(<ListItem key="first-page" content={1} />);
    }

    if (shouldShowTruncate && currentPage >= 4) {
      numbersArray.push(
        <div key="truncate-before" className={styles['btn-number']}>
          ...
        </div>
      );
    }

    // eslint-disable-next-line no-plusplus
    for (let i = minNumber; i <= maxNumber; i++) {
      numbersArray.push(<ListItem key={i} isActive={currentPage === i} content={i} />);
    }

    if (shouldShowTruncate && currentPage <= numberOfPaginationPages - 3) {
      numbersArray.push(
        <div key="truncate-after" className={styles['btn-number']}>
          ...
        </div>
      );
    }

    if (shouldShowTruncate && currentPage <= numberOfPaginationPages - 2) {
      numbersArray.push(
        <ListItem key="numberOfPaginationPages" content={numberOfPaginationPages} />
      );
    }

    return numbersArray;
  }, [ListItem, currentPage, numberOfPaginationPages]);

  // Only show pagination if pages is more than 1
  return numberOfPaginationPages > 1 ? (
    <div className={styles.paginate} style={{ color: `var(--color-${color})` }}>
      {currentPage === 1 ? (
        <div className={styles['btn-arrow']}>
          <Icon icon={chevronLeft} color={Colors.DarkBlue500} />
        </div>
      ) : (
        <Link href={hrefUrl(currentPage - 1)} passHref>
          <a className={styles['btn-arrow']} color={color}>
            <Icon icon={chevronLeft} color={color} />
          </a>
        </Link>
      )}
      <ol className={styles.list}>{numbers}</ol>
      {currentPage === numberOfPaginationPages ? (
        <div className={styles['btn-arrow']}>
          <Icon icon={chevronRight} color={Colors.DarkBlue500} />
        </div>
      ) : (
        <Link href={hrefUrl(currentPage + 1)} passHref>
          <a className={styles['btn-arrow']}>
            <Icon icon={chevronRight} color={color} />
          </a>
        </Link>
      )}
    </div>
  ) : null;
};
