/* eslint-disable no-fallthrough */

import { ParsedUrlQuery } from 'querystring';

import { IFilters } from 'utils/marketplaceAdsFilter';
import { FilterCollectionQueries, FilterQueries, FuelHardCoded, GearBox } from 'enums/Filter';
import {
  getParsedUrlQueryAsNumber,
  getParsedUrlQueryAsString,
  getParsedUrlQueryAsStringArray,
} from 'utils/getParsedUrlAs';

const ITEMS_PER_PAGE = 39;

const getFilters = (query: ParsedUrlQuery): IFilters => {
  const filters: IFilters = { tag: '' }; // tag needs to filter out sold items

  // Early return
  if (!query) {
    return filters;
  }

  if (query[FilterCollectionQueries.BigLuggage] !== undefined) {
    filters.rear_trunk_volume = {
      min: 600,
    };
  }

  if (query[FilterCollectionQueries.NumberOfSeats] !== undefined) {
    filters.number_of_seats = {
      min: 6,
    };
  }

  if (query[FilterCollectionQueries.LongRange] !== undefined) {
    filters.range = {
      min: 500,
    };
  }

  if (query[FilterCollectionQueries.Price] !== undefined) {
    filters.price = {
      max: 100000,
    };
  }

  if (query[FilterCollectionQueries.PopularCars] !== undefined) {
    filters.series = [
      'XC90',
      'XC60',
      'V40',
      'V60',
      'V90',
      'Golf',
      'Passat',
      'Ceed',
      'A6',
      'Tiguan',
      'Clio',
    ];
    filters.brand = ['Volkswagen', 'Volvo', 'Kia', 'Audi', 'Renault'];
  }

  if (query[FilterCollectionQueries.SmallCityCars] !== undefined) {
    filters.classification_en = ['Small Family Car'];
  }

  if (query[FilterCollectionQueries.CompanyLeasableVehicles] !== undefined) {
    filters.is_company_leasable = true;
  }

  if (query[FilterCollectionQueries.Rechargeable] !== undefined) {
    filters.fuel = ['Electric', 'Plug-in Hybrid Petrol', 'Plug-in Hybrid Diesel'];
  }

  if (query[FilterCollectionQueries.NewCars] !== undefined) {
    const twoYearsAgo = new Date().getFullYear() - 2;

    filters.model_year = {
      min: twoYearsAgo,
    };
  }

  if (query[FilterQueries.Brand] !== undefined) {
    filters.brand = getParsedUrlQueryAsStringArray(query[FilterQueries.Brand]);
  }

  if (query[FilterQueries.Series] !== undefined) {
    filters.series = getParsedUrlQueryAsStringArray(query[FilterQueries.Series]);
  }

  if (query[FilterQueries.Fuel] !== undefined) {
    const activeFuelFromQuery = getParsedUrlQueryAsString(query[FilterQueries.Fuel]);
    switch (activeFuelFromQuery) {
      case FuelHardCoded.Electric:
        filters.fuel = ['Electric'];
        break;
      case FuelHardCoded.Diesel:
        filters.fuel = ['Diesel'];
        break;
      case FuelHardCoded.Gasoline:
        filters.fuel = ['Petrol'];
        break;
      case FuelHardCoded.Environment:
        filters.fuel = [
          'Ethanol (E85)',
          'Hybrid Petrol',
          'Mild-Hybrid Diesel',
          'Mild-Hybrid Petrol',
          'Natural Gas (CNG)',
          'Plug-in Hybrid Diesel',
          'Plug-in Hybrid Petrol',
        ];
        break;
      default:
        break;
    }
  }

  if (query[FilterQueries.PriceMin] !== undefined || query[FilterQueries.PriceMax] !== undefined) {
    filters.price = {
      ...(query[FilterQueries.PriceMin]
        ? { min: getParsedUrlQueryAsNumber(query[FilterQueries.PriceMin]) }
        : {}),
      ...(query[FilterQueries.PriceMax]
        ? { max: getParsedUrlQueryAsNumber(query[FilterQueries.PriceMax]) }
        : {}),
    };
  }

  if (
    query[FilterQueries.YearModelMin] !== undefined ||
    query[FilterQueries.YearModelMax] !== undefined
  ) {
    filters.model_year = {
      ...(query[FilterQueries.YearModelMin]
        ? { min: getParsedUrlQueryAsString(query[FilterQueries.YearModelMin]) }
        : {}),
      ...(query[FilterQueries.YearModelMax]
        ? { max: getParsedUrlQueryAsString(query[FilterQueries.YearModelMax]) }
        : {}),
    };
  }

  if (
    query[FilterQueries.HorsepowerMin] !== undefined ||
    query[FilterQueries.HorsepowerMax] !== undefined
  ) {
    filters.horsepower = {
      ...(query[FilterQueries.HorsepowerMin]
        ? { min: getParsedUrlQueryAsNumber(query[FilterQueries.HorsepowerMin]) }
        : {}),
      ...(query[FilterQueries.HorsepowerMax]
        ? { max: getParsedUrlQueryAsNumber(query[FilterQueries.HorsepowerMax]) }
        : {}),
    };
  }

  if (
    query[FilterQueries.MileageMin] !== undefined ||
    query[FilterQueries.MileageMax] !== undefined
  ) {
    filters.latest_milage = {
      ...(query[FilterQueries.MileageMin]
        ? { min: getParsedUrlQueryAsNumber(query[FilterQueries.MileageMin]) }
        : {}),
      ...(query[FilterQueries.MileageMax]
        ? { max: getParsedUrlQueryAsNumber(query[FilterQueries.MileageMax]) }
        : {}),
    };
  }

  if (query[FilterQueries.RangeMin] !== undefined || query[FilterQueries.RangeMax] !== undefined) {
    filters.range = {
      ...(query[FilterQueries.RangeMin]
        ? { min: getParsedUrlQueryAsString(query[FilterQueries.RangeMin]) }
        : {}),
      ...(query[FilterQueries.RangeMax]
        ? { max: getParsedUrlQueryAsString(query[FilterQueries.RangeMax]) }
        : {}),
    };
  }
  if (query[FilterQueries.Location] !== undefined) {
    filters.municipality_county = getParsedUrlQueryAsStringArray(query[FilterQueries.Location]);
  }
  if (query[FilterQueries.VehicleType] !== undefined) {
    filters.vehicle_type = getParsedUrlQueryAsStringArray(query[FilterQueries.VehicleType]);
  }

  if (query[FilterQueries.GearBox] !== undefined) {
    filters.gearbox = [
      getParsedUrlQueryAsString(query[FilterQueries.GearBox]) === GearBox.Automatic
        ? 'Automatic'
        : 'Manual',
    ];
  }

  if (query[FilterQueries.Colors] !== undefined) {
    filters.colour = getParsedUrlQueryAsStringArray(query[FilterQueries.Colors]);
  }

  return filters;
};

export const getQueryParams = (query: ParsedUrlQuery) => {
  const { page, search } = query;
  const filters = getFilters(query);
  let pageNumber = 1;

  if (page && typeof page === 'string') {
    pageNumber = parseInt(page, 10);
  }

  return { page: pageNumber, perPage: ITEMS_PER_PAGE, filters, search };
};
