import React from 'react';

import styles from './InputCheckboxColor.module.scss';

interface IInputCheckboxColor {
  name: string;
  value: string;
  labelText: string;
  color: string;
  [rest: string]: any; // accept any props to be spread to select element
}

export const InputCheckboxColor = React.forwardRef<HTMLInputElement, IInputCheckboxColor>(
  ({ name, value, labelText, color, ...rest }, ref) => (
    <label className={`${styles.checkbox}`}>
      <input
        name={name}
        value={value}
        id={value}
        ref={ref}
        {...rest}
        type="checkbox"
        style={{
          background:
            color === 'silver'
              ? 'linear-gradient(135deg, #E3E3E3 26.74%, #FFFFFF 44.32%, #FFFFFF 52.43%, #CFD3DB 75.65%, #A6ABB5 100.2%)'
              : color,
        }}
      />
      <span className="u-a11y-hide">{labelText}</span>
    </label>
  )
);
