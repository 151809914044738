import { useEffect, useRef } from 'react';
import useSWR from 'swr';

import { ClientApiService } from 'services/ClientApiService';
import { MarketPlaceAdsFilterResponse } from 'types/MarketPlaceAdsFilterResponse';

export function useVehiclesFilterData(
  params: string | null,
  serverData?: MarketPlaceAdsFilterResponse
): {
  data?: MarketPlaceAdsFilterResponse['payload']['filters'];
  isLoading: boolean;
  error: any;
} {
  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  const { data, error } = useSWR(
    ['/api/proxy', { method: 'getMarketplaceAdsFilter', payload: { params } }],
    ClientApiService.swrFetcher,
    {
      fallbackData: hasMounted.current ? undefined : { body: { ...serverData } }, // Only serve fallbackData on first render to prevent double fetching
      revalidateIfStale: false,
      revalidateOnMount: true, // TODO fix this to not need to re-fetch from server to client with same data
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    data: data?.body?.payload?.filters,
    error,
    isLoading: !error && !data,
  };
}
