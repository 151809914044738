import React, { useMemo } from 'react';

import styles from './Dropdown.module.scss';

export enum DropdownSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface IOptionDropdown {
  disabled?: boolean;
  value: string | number;
  label: string | number;
}

interface ISelect {
  options: Array<IOptionDropdown>;
  fullWidth?: boolean;
  size?: DropdownSize;
  [rest: string]: any; // accept any props to be spread to select element
}

export const Dropdown = React.forwardRef<HTMLSelectElement, ISelect>(
  ({ options, fullWidth, size = DropdownSize.Medium, ...rest }, ref) => {
    const sizeClassName = useMemo(() => {
      switch (size) {
        case DropdownSize.Small:
          return styles['select--small'];
        case DropdownSize.Medium:
          return styles['select--medium'];
        case DropdownSize.Large:
          return styles['select--large'];
        default:
          return styles['select--medium'];
      }
    }, [size]);

    return (
      <select
        {...rest}
        ref={ref}
        className={`${styles.select} ${
          fullWidth ? styles['select--fullwidth'] : ''
        } ${sizeClassName}`}
      >
        {options.map((option) => (
          <option disabled={option.disabled} key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }
);
