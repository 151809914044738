import React from 'react';

import styles from './InputRangeResult.module.scss';

interface IInputRangeResult {
  firstValue: string | number;
  secondValue?: string | number;
}

export const InputRangeResult: React.VFC<IInputRangeResult> = ({ firstValue, secondValue }) => (
  <span className={styles.wrapper}>
    <span>{firstValue}</span>
    {secondValue ? (
      <>
        <span>-</span>
        <span>{secondValue}</span>
      </>
    ) : null}
  </span>
);
