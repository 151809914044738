import { getTranslations } from 'utils/getTranslations';

import { IFormData } from './types';

export const getArrayWithoutEmptyValue = (array: Array<string>) => array.filter((item) => item);

export const getQueriesByDataFromFilter = (data: IFormData, defaultValues: IFormData) =>
  Object.keys(data).reduce((acc, currentKey): Record<string, unknown> => {
    // Don't filter default values
    if (data[currentKey] === defaultValues[currentKey]) {
      return acc;
    }

    if (Array.isArray(data[currentKey])) {
      const arrayWithoutEmptyValues = getArrayWithoutEmptyValue(data[currentKey]);

      if (!arrayWithoutEmptyValues.length) {
        return acc;
      }
      return { ...acc, [currentKey]: arrayWithoutEmptyValues };
    }

    // don't filter and add queries that are empty values
    if (data[currentKey] === '') {
      return acc;
    }

    if (data[currentKey]) {
      return { ...acc, [currentKey]: data[currentKey] };
    }

    return acc;
  }, {});

export const getSelectOptions = (
  arrayOfStrings: Array<string> | undefined,
  defaultLabel: string
) => {
  let optionsArray = [{ value: '', label: defaultLabel }];

  if (arrayOfStrings) {
    arrayOfStrings.forEach((item: string) => {
      optionsArray = [...optionsArray, { value: item, label: getTranslations(item) }];
    });
  }

  return optionsArray;
};

export const getMinStep = (value: number, step: number) => Math.floor(value / step) * step;
export const getMaxStep = (value: number, step: number) => Math.ceil(value / step) * step;
