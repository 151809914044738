import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputCheckboxColor } from 'components/InputCheckboxColor';
import { FilterQueries } from 'enums/Filter';

import styles from './FilterColors.module.scss';

interface IFilterColors {
  colors: Array<string>;
}

export const FilterColors: React.VFC<IFilterColors> = ({ colors }) => {
  const { getValues } = useFormContext();
  const currentChosenColors = getValues(FilterQueries.Colors);

  return (
    <ul className={styles.list}>
      {colors.map((item: string, index: number) => (
        <li key={item}>
          <Controller
            name={`${FilterQueries.Colors}[${index}]`}
            render={({ field }) => (
              <InputCheckboxColor
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                color={item.toLowerCase()}
                value={item}
                checked={currentChosenColors.includes(item)} // need to check in array because colors and field gets different index
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event.target.checked ? item : undefined);
                }}
              />
            )}
          />
        </li>
      ))}
    </ul>
  );
};
