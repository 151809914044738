import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import { Dropdown } from 'components/Dropdown';
import { DropdownSize } from 'components/Dropdown/Dropdown';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import { FilterQueries, FuelHardCoded, LocationHardCoded, GearBox } from 'enums/Filter';
import { Filters } from 'types/MarketPlaceAdsFilterResponse';
import useDebounce from 'utils/useDebounce';
import { InputRangeMulti } from 'components/InputRangeMulti';
import { InputRangeMultiVariants } from 'components/InputRangeMulti/InputRangeMulti';
import {
  getParsedUrlQueryAsNumber,
  getParsedUrlQueryAsString,
  getParsedUrlQueryAsStringArray,
} from 'utils/getParsedUrlAs';
import { InputRangeResult } from 'components/InputRangeResult';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { getFormattedNumber } from 'utils/getFormattedNumber';
import { ButtonFontWeight } from 'components/Button/Button';
import { FilterColors } from 'components/views/ProductList/components/FilterColors';
import { getTranslations } from 'utils/getTranslations';

import {
  getArrayWithoutEmptyValue,
  getMaxStep,
  getMinStep,
  getQueriesByDataFromFilter,
  getSelectOptions,
} from './utils';
import styles from './Filter.module.scss';
import { IFormData } from './types';

const PRICE_STEP = 10000;
const MILEAGE_STEP = 500;
const RANGE_STEP = 10;

interface IFilter {
  closeFilterModal?: () => void;
  filterValues: Filters;
  filterSeries: Filters['series'];
  filterSeriesIsLoading: boolean;
}

export const Filter: React.VFC<IFilter> = ({
  closeFilterModal,
  filterValues,
  filterSeries,
  filterSeriesIsLoading,
}) => {
  const hasMounted = useRef(false);
  const { query, push } = useRouter();
  const {
    price: { min: minPriceRange, max: maxPriceRange },
    model_year: { min: yearModelMin },
    vehicle_type: vehicleType,
    horsepower: { min: horsePowerMin, max: horsePowerMax },
    latest_milage: { min: mileageMin, max: mileageMax },
    range: { min: rangeMin, max: rangeMax },
    colour,
  } = filterValues;

  const thisYearPlusOneYear = useMemo(() => new Date().getFullYear() + 1, []);
  const municipalityCountys = useMemo(
    () => getSelectOptions(Object.values(LocationHardCoded), 'Hela Sverige'),
    []
  );
  const vehicleValues = useMemo(() => getSelectOptions(vehicleType, 'Fordonstyp'), [vehicleType]);
  const brandValues = useMemo(() => getSelectOptions(filterValues?.brand, 'Märke'), [filterValues]);
  const seriesValues = useMemo(() => getSelectOptions(filterSeries, 'Modell'), [filterSeries]);
  const fuelValues = useMemo(() => getSelectOptions(Object.values(FuelHardCoded), 'Drivmedel'), []);

  const defaultValues = useMemo(
    () => ({
      [FilterQueries.Location]: '',
      [FilterQueries.VehicleType]: '',
      [FilterQueries.Brand]: '',
      [FilterQueries.Series]: '',
      [FilterQueries.Fuel]: '',
      [FilterQueries.PriceMin]: getMinStep(minPriceRange, PRICE_STEP),
      [FilterQueries.PriceMax]: getMaxStep(maxPriceRange, PRICE_STEP),
      [FilterQueries.YearModelMax]: thisYearPlusOneYear,
      [FilterQueries.YearModelMin]: yearModelMin,
      [FilterQueries.HorsepowerMin]: horsePowerMin,
      [FilterQueries.HorsepowerMax]: horsePowerMax,
      [FilterQueries.MileageMin]: getMinStep(mileageMin, MILEAGE_STEP),
      [FilterQueries.MileageMax]: getMaxStep(mileageMax, MILEAGE_STEP),
      [FilterQueries.RangeMin]: getMinStep(rangeMin, RANGE_STEP),
      [FilterQueries.RangeMax]: getMaxStep(rangeMax, RANGE_STEP),
      [FilterQueries.GearBox]: GearBox.Both,
      [FilterQueries.Colors]: colour.map(() => ''),
    }),
    [
      colour,
      horsePowerMax,
      horsePowerMin,
      maxPriceRange,
      mileageMax,
      mileageMin,
      minPriceRange,
      rangeMax,
      rangeMin,
      thisYearPlusOneYear,
      yearModelMin,
    ]
  );

  // react hook form change order on active items selected vs loop of colors
  const getDefaultColorsFromPageQuery = useCallback(
    (selectedColorsFromQuery: Array<string>) =>
      colour.reduce<Array<string>>(
        (acc, curr) => [...acc, selectedColorsFromQuery.includes(curr) ? curr : ''],
        []
      ),
    [colour]
  );

  const defaultValuesFromPageLoad = useMemo(
    () => ({
      [FilterQueries.Location]: getParsedUrlQueryAsString(query[FilterQueries.Location]),
      [FilterQueries.VehicleType]: getParsedUrlQueryAsString(query[FilterQueries.VehicleType]),
      [FilterQueries.Brand]: getParsedUrlQueryAsString(query[FilterQueries.Brand]),
      [FilterQueries.Series]: getParsedUrlQueryAsString(query[FilterQueries.Series]),
      [FilterQueries.Fuel]: getParsedUrlQueryAsString(query[FilterQueries.Fuel]),
      [FilterQueries.PriceMin]:
        getParsedUrlQueryAsNumber(query[FilterQueries.PriceMin]) ??
        defaultValues[FilterQueries.PriceMin],
      [FilterQueries.PriceMax]:
        getParsedUrlQueryAsNumber(query[FilterQueries.PriceMax]) ??
        defaultValues[FilterQueries.PriceMax],
      [FilterQueries.YearModelMax]:
        getParsedUrlQueryAsNumber(query[FilterQueries.YearModelMax]) ??
        defaultValues[FilterQueries.YearModelMax],
      [FilterQueries.YearModelMin]:
        getParsedUrlQueryAsNumber(query[FilterQueries.YearModelMin]) ??
        defaultValues[FilterQueries.YearModelMin],
      [FilterQueries.HorsepowerMin]:
        getParsedUrlQueryAsNumber(query[FilterQueries.HorsepowerMin]) ??
        defaultValues[FilterQueries.HorsepowerMin],
      [FilterQueries.HorsepowerMax]:
        getParsedUrlQueryAsNumber(query[FilterQueries.HorsepowerMax]) ??
        defaultValues[FilterQueries.HorsepowerMax],
      [FilterQueries.MileageMin]:
        getParsedUrlQueryAsNumber(query[FilterQueries.MileageMin]) ??
        defaultValues[FilterQueries.MileageMin],
      [FilterQueries.MileageMax]:
        getParsedUrlQueryAsNumber(query[FilterQueries.MileageMax]) ??
        defaultValues[FilterQueries.MileageMax],
      [FilterQueries.RangeMin]:
        getParsedUrlQueryAsNumber(query[FilterQueries.RangeMin]) ??
        defaultValues[FilterQueries.RangeMin],
      [FilterQueries.RangeMax]:
        getParsedUrlQueryAsNumber(query[FilterQueries.RangeMax]) ??
        defaultValues[FilterQueries.RangeMax],
      [FilterQueries.GearBox]:
        getParsedUrlQueryAsString(query[FilterQueries.GearBox]) ??
        defaultValues[FilterQueries.GearBox],
      [FilterQueries.Colors]:
        getDefaultColorsFromPageQuery(
          getParsedUrlQueryAsStringArray(query[FilterQueries.Colors])
        ) ?? defaultValues[FilterQueries.Colors],
    }),
    [defaultValues, getDefaultColorsFromPageQuery, query]
  );

  const [activeGearBox, setActiveGearBox] = useState(
    defaultValuesFromPageLoad[FilterQueries.GearBox]
  );

  const getNumberOfActiveFilters = useCallback(
    (activeQueriesObj) => Object.keys(activeQueriesObj).length,
    []
  );

  const [formData, setFormData] = useState<IFormData>(defaultValuesFromPageLoad);
  const [numberOfActiveFilters, setNumberOfActiveFilters] = useState(
    getNumberOfActiveFilters(getQueriesByDataFromFilter(defaultValuesFromPageLoad, defaultValues))
  );
  const debouncedFormData = useDebounce(formData, 500);

  const methods = useForm<IFormData>({
    defaultValues: defaultValuesFromPageLoad,
  });
  const { handleSubmit, register, watch, reset, control, setValue, getValues } = methods;
  const watchBrand = watch(FilterQueries.Brand, defaultValuesFromPageLoad[FilterQueries.Brand]);

  const pushQueriesToUrl = useCallback(() => {
    const newQueriesByDataFromFilter = getQueriesByDataFromFilter(formData, defaultValues);
    setNumberOfActiveFilters(getNumberOfActiveFilters(newQueriesByDataFromFilter));
    push(
      {
        pathname: '/fordon',
        query: newQueriesByDataFromFilter,
      },
      undefined,
      { shallow: true }
    );
  }, [defaultValues, formData, getNumberOfActiveFilters, push]);

  useEffect(() => {
    pushQueriesToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFormData]); // don't include other deps otherwise circular deps

  const onSubmit = useCallback(() => {
    if (closeFilterModal) {
      closeFilterModal();
    }
  }, [closeFilterModal]);

  const clearFilter = useCallback(() => {
    setActiveGearBox(GearBox.Both);
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleActiveGearBox = (clickedButton: GearBox) => {
    setActiveGearBox((prevState) => {
      switch (true) {
        case prevState === clickedButton:
          return GearBox.Both;
        case clickedButton === GearBox.Automatic:
          return GearBox.Automatic;
        case clickedButton === GearBox.Manuel:
          return GearBox.Manuel;
        default:
          return GearBox.Both;
      }
    });
  };

  useEffect(() => {
    setFormData((prevState) => ({ ...prevState, [FilterQueries.GearBox]: activeGearBox }));
  }, [activeGearBox]);

  const getColorsAsStringAndTranslated = useCallback(
    (colors: Array<string>) => colors.reduce((acc, curr) => `${acc} ${getTranslations(curr)}`, ''),
    []
  );

  const getColorsSelected = useCallback(
    (colors) => {
      const filteredColors = getArrayWithoutEmptyValue(colors);
      return getColorsAsStringAndTranslated(filteredColors) || 'Ingen färg vald';
    },
    [getColorsAsStringAndTranslated]
  );

  useEffect(() => {
    const subscription = watch((data) => {
      const filterFormData = {
        ...data,
        [FilterQueries.GearBox]: activeGearBox,
      };
      // @ts-ignore
      setFormData(filterFormData);
    });
    return () => subscription.unsubscribe();
  }, [activeGearBox, query, watch]);

  // clear default value for series when user select another brand and series has fetch new data
  useEffect(() => {
    if (hasMounted.current) {
      setValue(FilterQueries.Series, '');
    }
  }, [seriesValues, setValue]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles['content-wrapper']}>
          <div className={styles['content-inner-wrapper']}>
            <label
              className={`${styles.label} ${styles.locationLabel}`}
              htmlFor="municipality_county"
            >
              <Dropdown
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...register(FilterQueries.Location)}
                size={DropdownSize.Small}
                options={municipalityCountys}
                fullWidth
                id="municipality_county"
              />
            </label>
            <label className={styles.label} htmlFor="vehicle-type">
              <Dropdown
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...register(FilterQueries.VehicleType)}
                size={DropdownSize.Small}
                options={vehicleValues}
                fullWidth
                id="vehicle-type"
              />
            </label>
            <label className={styles.label} htmlFor="brand">
              <Dropdown
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...register(FilterQueries.Brand)}
                size={DropdownSize.Small}
                options={brandValues}
                fullWidth
                id="brand"
              />
            </label>
            <label className={styles.label} htmlFor="series">
              <Controller
                control={control}
                name={FilterQueries.Series}
                render={({ field }) => (
                  <Dropdown
                    size={DropdownSize.Small}
                    options={seriesValues}
                    disabled={!watchBrand || filterSeriesIsLoading}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    fullWidth
                    id="series"
                  />
                )}
              />
            </label>
            <label className={styles.label} htmlFor="fuel">
              <span className="u-a11y-hide">Drivmedel</span>
              <Controller
                control={control}
                name={FilterQueries.Fuel}
                render={({ field }) => (
                  <Dropdown
                    size={DropdownSize.Small}
                    options={fuelValues}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    fullWidth
                    id="fuel"
                  />
                )}
              />
            </label>
            <div className={styles['gearbox-section']}>
              <div className={styles['label-wrapper']}>
                <h3 className={styles.label}>Växellåda</h3>
              </div>
              <div className={styles['gearbox-buttons-wrapper']}>
                <Button
                  type={ButtonType.Button}
                  variant={
                    activeGearBox === GearBox.Automatic
                      ? ButtonVariant.PrimaryYellowish
                      : ButtonVariant.Tertiary
                  }
                  size={ButtonSize.Small}
                  fontWeight={ButtonFontWeight.Regular}
                  onClick={() => handleActiveGearBox(GearBox.Automatic)}
                >
                  Automat
                </Button>
                <Button
                  type={ButtonType.Button}
                  variant={
                    activeGearBox === GearBox.Manuel
                      ? ButtonVariant.PrimaryYellowish
                      : ButtonVariant.Tertiary
                  }
                  size={ButtonSize.Small}
                  fontWeight={ButtonFontWeight.Regular}
                  onClick={() => handleActiveGearBox(GearBox.Manuel)}
                >
                  Manuell
                </Button>
              </div>
            </div>
            <div className={styles['input-range-wrapper']}>
              <div className={styles['label-wrapper']}>
                <h3 className={styles.label}>Pris</h3>
              </div>
              <InputRangeResult
                firstValue={getFormattedNumber(getValues(FilterQueries.PriceMin))}
                secondValue={getFormattedPrice(getValues(FilterQueries.PriceMax))}
              />
              <InputRangeMulti
                colorVariant={InputRangeMultiVariants.SkyBlue}
                min={defaultValues[FilterQueries.PriceMin]}
                max={defaultValues[FilterQueries.PriceMax]}
                step={PRICE_STEP}
                minName={FilterQueries.PriceMin}
                maxName={FilterQueries.PriceMax}
                minLabel="Pris min"
                maxLabel="Pris max"
              />
            </div>
            <div className={styles['input-range-wrapper']}>
              <div className={styles['label-wrapper']}>
                <h3 className={styles.label}>Årsmodell</h3>
              </div>
              <InputRangeResult
                firstValue={getValues(FilterQueries.YearModelMin)}
                secondValue={getValues(FilterQueries.YearModelMax)}
              />
              <InputRangeMulti
                colorVariant={InputRangeMultiVariants.SkyBlue}
                min={yearModelMin}
                max={thisYearPlusOneYear}
                step={1}
                minName={FilterQueries.YearModelMin}
                maxName={FilterQueries.YearModelMax}
                minLabel="Årsmodell min"
                maxLabel="Årsmodell max"
              />
            </div>
            <div className={styles['input-range-wrapper']}>
              <div className={styles['label-wrapper']}>
                <h3 className={styles.label}>Miltal</h3>
              </div>
              <InputRangeResult
                firstValue={`${getFormattedNumber(getValues(FilterQueries.MileageMin))}`}
                secondValue={`${getFormattedNumber(getValues(FilterQueries.MileageMax))} mil`}
              />
              <InputRangeMulti
                colorVariant={InputRangeMultiVariants.SkyBlue}
                min={defaultValues[FilterQueries.MileageMin]}
                max={defaultValues[FilterQueries.MileageMax]}
                step={MILEAGE_STEP}
                minName={FilterQueries.MileageMin}
                maxName={FilterQueries.MileageMax}
                minLabel="Miltal min"
                maxLabel="Miltal max"
              />
            </div>
            {/* Save for later when enabling range filter */}
            {/*  <div className={styles['input-range-wrapper']}>
              <div className={styles['label-wrapper']}>
                <h3 className={styles.label}>Räckvidd elbil</h3>
              </div>
              <InputRangeResult
                firstValue={`${getFormattedNumber(getValues(FilterQueries.RangeMin))} km`}
                secondValue={`${getFormattedNumber(getValues(FilterQueries.RangeMax))} km`}
              />
              <InputRangeMulti
                colorVariant={InputRangeMultiVariants.SkyBlue}
                min={defaultValues[FilterQueries.RangeMin]}
                max={defaultValues[FilterQueries.RangeMax]}
                step={RANGE_STEP}
                minName={FilterQueries.RangeMin}
                maxName={FilterQueries.RangeMax}
                minLabel="Räckvidd elbil min"
                maxLabel="Räckvidd elbil max"
              />
            </div> */}
            <div className={styles['input-range-wrapper']}>
              <div className={styles['label-wrapper']}>
                <h3 className={styles.label}>Hästkrafter</h3>
              </div>
              <InputRangeResult
                firstValue={`${getValues(FilterQueries.HorsepowerMin)}`}
                secondValue={`${getValues(FilterQueries.HorsepowerMax)} hk`}
              />
              <InputRangeMulti
                colorVariant={InputRangeMultiVariants.SkyBlue}
                min={horsePowerMin}
                max={horsePowerMax}
                step={1}
                minName={FilterQueries.HorsepowerMin}
                maxName={FilterQueries.HorsepowerMax}
                minLabel="Hästkraft min"
                maxLabel="Hästkraft max"
              />
            </div>
            <div className={styles['input-range-wrapper']}>
              <div className={styles['label-wrapper']}>
                <h3 className={styles.label}>Färg</h3>
              </div>
              <InputRangeResult
                firstValue={`${getColorsSelected(getValues(FilterQueries.Colors))}`}
              />
              <FilterColors colors={colour} />
            </div>
          </div>
        </div>
        <div className={styles['bottom-content-wrapper']}>
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.GhostInverted}
            onClick={clearFilter}
            disabled={!numberOfActiveFilters}
          >
            {`Rensa ${numberOfActiveFilters ? `(${numberOfActiveFilters})` : ''}`}
          </Button>
          <Button type={ButtonType.Submit} variant={ButtonVariant.PrimaryMintSweet}>
            Visa
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
