export const carLoanHero = {
  title: 'Låna till din nya bil',
  text: 'Med Blipp kan du låna till bättre villkor än ett privatlån. Låna med fordonet som säkerhet och gör en trygg och säker affär.',
  mobileSrc: '/assets/images/loan-hero-mobile.jpg',
  desktopSrc: '/assets/images/loan-hero-desktop.jpg',
  alt: 'Guy inside camper van overlooking a mountain chain',
};

export const privateLoanHero = {
  title: 'Ansök om ett privatlån utan kontantinsats',
  text: 'För den som vill köpa ett fordon där ett vanligt lån inte passar eller inte vill betala kontantinsats lanserar Blipp nu ett privatlån i samarbete med Moank som du kan använda för bilköpet.',
  mobileSrc: '/assets/images/privateloan-hero-mobile.jpg',
  desktopSrc: '/assets/images/privateloan-hero-desktop.jpg',
  alt: 'Guy inside camper van overlooking a mountain chain',
};

export const releaseHero = {
  title: 'Kan vi sänka din månadskostnad?',
  text: 'Med Blipp kan du lägga om ditt nuvarande leasingavtal på din företagsbil.',
  mobileSrc: '/assets/images/releasing-hero-mobile.jpg',
  desktopSrc: '/assets/images/releasing-hero-desktop.jpg',
  alt: 'Lägg om företagsleasing',
  heroButtonText: 'Anmäl intresse',
};

export const ownershipHero = {
  title: 'Med Blipp är det enkelt att äga',
  text: 'Tack vare våra samarbetspartners gör vi fordonsägandet enkelt, tryggt och alltid på dina villkor.',
  mobileSrc: '/assets/images/ownit-hero-mobile.jpg',
  desktopSrc: '/assets/images/ownit-hero-desktop.jpg',
  alt: 'Guy inside camper van overlooking a mountain chain',
};

export const companyLeasingHero = {
  title: 'Företagsleasing — finansiera dina företagsbilar via Blipp',
  text: 'Med Blipp kan du företagsleasa begagnade bilar utan att gå via bilhandlaren.',
  mobileSrc: '/assets/images/companyleasing-hero-mobile.jpg',
  desktopSrc: '/assets/images/companyleasing-hero-desktop.jpg',
  alt: 'Guy inside camper van overlooking a mountain chain',
};

export const companyHero = {
  title: 'Det smarta sättet att samla dina företagsbilar',
  text: 'Blipp förenklar processen för dig som företagare när du ska skaffa bil. Det gäller oavsett om du vill leasa en eller flera bilar eller lägga om en nuvarande leasing. Vi hjälper även till när du vill sälja företagets bilar.',
  mobileSrc: '/assets/images/company-hero-mobile.jpg',
  desktopSrc: '/assets/images/company-hero-desktop.jpg',
  alt: 'Truck driving in a highway towards the sunset',
};

export const insuranceAndWarrantyHero = {
  title: 'Försäkring & Garanti',
  text: 'När du köper med Blipp erbjuder vi dig en gratis försäkring i 14 dagar via Trygg-Hansa. Du slipper alltså tänka på försäkringen direkt vid köpet. Vi erbjuder också en Blipp-garanti mot dolda fel helt utan självrisk.',
  mobileSrc: '/assets/images/woman-on-bike-mobile.jpg',
  desktopSrc: '/assets/images/woman-on-bike-desktop.jpg',
  alt: 'Woman on bike wearing a helmet',
};

export const carListHero = {
  title: 'Här hittar du din nästa bil',
  mobileSrc: '/assets/images/car-with-open-door-mobile.jpg',
  desktopSrc: '/assets/images/car-with-open-door-desktop.jpg',
};

export const termsHero = {
  title: 'Här hittar du våra villkor',
  text: 'Det finstilta, men ack så viktiga att ta hänsyn till och läsa igenom. Har du frågor är du alltid välkommen att fråga oss. Våra kontaktuppgifter hittar du nederst på sidan.',
  mobileSrc: '/assets/images/car-with-open-door-mobile.jpg',
  desktopSrc: '/assets/images/car-with-open-door-desktop.jpg',
};

export const aboutHero = {
  title: '',
  text: '',
  mobileSrc: '/assets/images/about-us-mobile.png',
  desktopSrc: '/assets/images/about-us.jpg',
};
