import React, { useContext, useState } from 'react';
import {
  ModalPortal,
  useBodyScrollLock,
  useCloseOnEsc,
  useTrapFocus,
} from '@weahead/react-customizable-modal';

import { Icon } from 'components/tokens/Icon';
import { closeCircle, filter } from 'icons/default';
import { Colors } from 'enums/Colors';
import { Filters } from 'types/MarketPlaceAdsFilterResponse';
import { FilterContext, IFilterContext } from 'utils/FilterContext';
import { StatusWithIcon } from 'components/StatusWithIcon';
import {
  StatusWithIconIconSize,
  StatusWithIconVariant,
} from 'components/StatusWithIcon/StatusWithIcon';

import { Filter } from '../Filter';

import styles from './FilterMobile.module.scss';

interface IIFilterModal {
  onClose: () => void;
}

const FilterModal: React.VFC<IIFilterModal> = ({ onClose }) => {
  const {
    filterData,
    filterIsLoading,
    filterError,
    filterSeriesData,
    filterSeriesIsLoading,
    filterSeriesError,
  } = useContext<IFilterContext>(FilterContext);
  useBodyScrollLock();
  useCloseOnEsc(onClose);
  const modalRef = useTrapFocus({ focusOnRender: true });

  return (
    <ModalPortal id="filter-modal">
      <div className={styles['modal-wrapper']}>
        <button aria-hidden type="button" className={styles.overlay} onClick={onClose} />
        <div className={styles['content-wrapper']} ref={modalRef}>
          <div className={styles.header}>
            <h2 className={styles.title}>Filter</h2>
            <button type="button" className={styles['close-btn']} onClick={onClose}>
              Stäng
              <Icon icon={closeCircle} color={Colors.White} />
            </button>
          </div>
          {filterError ? (
            <div className={styles['status-wrapper']}>
              <StatusWithIcon
                variant={StatusWithIconVariant.Error}
                text="Ladda om sidan genom att"
                title="Något gick fel!"
                link={{ href: '/fordon', text: 'klicka här.' }}
                sizeIcon={StatusWithIconIconSize.Small}
              />
            </div>
          ) : null}
          {filterIsLoading && !filterError ? (
            <div className={styles['status-wrapper']}>
              <video autoPlay loop muted playsInline>
                <source src="/assets/videos/blipp-logotyp-azur-animation.mp4" type="video/mp4" />
              </video>
              Laddar...
            </div>
          ) : null}
          {!filterIsLoading && !filterError ? (
            // these values can never be undefined due to loading and error state, thereby "as"
            <Filter
              filterValues={filterData as Filters}
              filterSeries={filterSeriesData as Filters['series']}
              filterSeriesIsLoading={filterSeriesIsLoading || filterSeriesError}
              closeFilterModal={onClose}
            />
          ) : null}
        </div>
      </div>
    </ModalPortal>
  );
};

export const FilterMobile: React.VFC = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <button
        className={styles['filter-button']}
        type="button"
        onClick={() => setIsFilterOpen(true)}
      >
        <Icon icon={filter} color={Colors.White} /> Visa filter
      </button>
      {isFilterOpen ? <FilterModal onClose={() => setIsFilterOpen(false)} /> : null}
    </>
  );
};
