// Code inspired by https://codesandbox.io/s/b9l0g?file=/src/multiRangeSlider/MultiRangeSlider.tsx

import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Colors } from 'enums/Colors';

import styles from './InputRangeMulti.module.scss';

export enum InputRangeMultiVariants {
  DarkBlue = Colors.DarkBlue900,
  SkyBlue = Colors.SkyBlue,
}

interface MultiRangeSliderProps {
  min: number;
  max: number;
  colorVariant?: InputRangeMultiVariants;
  step?: number;
  maxName: string;
  minName: string;
  minLabel: string;
  maxLabel: string;
}

export const InputRangeMulti: FC<MultiRangeSliderProps> = ({
  min,
  max,
  step = 1,
  colorVariant = InputRangeMultiVariants.DarkBlue,
  minLabel,
  maxLabel,
  minName,
  maxName,
}) => {
  const { register, watch, setValue } = useFormContext();
  const minValue = watch(minName);
  const maxValue = watch(maxName);
  const range = useRef<HTMLDivElement>(null);

  const minZIndex = useMemo(() => (minValue > max / 2 ? 2 : 1), [max, minValue]);
  const maxZIndex = useMemo(() => (maxValue < max / 2 ? 2 : 1), [max, maxValue]);

  const thumbColorClass = useMemo(() => {
    switch (colorVariant) {
      case InputRangeMultiVariants.DarkBlue:
        return 'thumb--dark-blue';
      case InputRangeMultiVariants.SkyBlue:
        return 'thumb--sky-blue';
      default:
        return 'thumb--dark-blue';
    }
  }, [colorVariant]);

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minValue);
    const maxPercent = getPercent(maxValue);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
      range.current.style.backgroundColor = `var(--color-${colorVariant})`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPercent, colorVariant, minValue]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValue);
    const maxPercent = getPercent(maxValue);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
      range.current.style.backgroundColor = `var(--color-${colorVariant})`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxValue, getPercent, colorVariant]);

  return (
    <span className={styles.wrapper}>
      <span className={styles.slider}>
        <label>
          <span className="u-a11y-hide">{minLabel}</span>
          <input
            {...register(minName, { valueAsNumber: true })}
            type="range"
            min={min}
            max={max}
            step={step}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = Math.min(+event.target.value, maxValue - step);
              setValue(minName, value);
            }}
            className={`${styles.thumb} ${styles[thumbColorClass]}`}
            style={{ zIndex: minZIndex }}
          />
        </label>
        <label>
          <span className="u-a11y-hide">{maxLabel}</span>
          <input
            {...register(maxName, { valueAsNumber: true })}
            type="range"
            min={min}
            max={max}
            step={step}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = Math.max(+event.target.value, minValue + step);
              setValue(maxName, value);
            }}
            className={`${styles.thumb} ${styles[thumbColorClass]}`}
            style={{ zIndex: maxZIndex }}
          />
        </label>
        <span className={styles['slider__track-wrapper']}>
          <span className={styles.slider__track} />
          <span ref={range} className={styles.slider__range} />
        </span>
      </span>
    </span>
  );
};
